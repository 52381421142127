@import url("./assets/fonts/fonts.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

.horizontal-scroll::-webkit-scrollbar {
  height: 0;
}

.solutions-dropdown {
  /* display: flex;
  flex-wrap: wrap; */
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* width: 800px; */
  width: 400px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px -2px #0801011a;
}

.solutions-dropdown li {
  /* width: 48%; */
  width: 100%;
}

.default-dropdown {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 400px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px -2px #0801011a;
}
.bg-custom-gradient {
  background: linear-gradient(
    to right,
    #699dd1 30%,
    #6396cc 30%,
    #5184bd 30%,
    #396caa 30% z
  );
}

.blog-gradient-text {
  background: linear-gradient(94.31deg, #6d8dff 3.5%, #102e99 128.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero-blog-detail-go-back-border {
  border: 2px solid transparent;
  border-image: linear-gradient(
    88.74deg,
    rgba(237, 237, 237, 0.15) -13.61%,
    rgba(237, 237, 237, 0.5) 49.36%,
    rgba(237, 237, 237, 0.15) 111.35%
  );
  border-image-slice: 1;
}

.light-card-border {
  position: relative;
  border: 2px solid transparent;
  border-radius: 10px;
  border-image-source: linear-gradient(white, white) padding-box,
    linear-gradient(
        104.1deg,
        rgba(255, 255, 255, 0.5) -16.16%,
        rgba(255, 255, 255, 0.25) 121.01%
      )
      border-box;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5),
    0 0 0 4px rgba(255, 255, 255, 0.25);
}

.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
